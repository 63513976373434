import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Alcool au volant : l’acquittement est-il possible en cas d’erreur policière?"
            description="Vous avez été arrêté pour alcool au volant ? Nous vous expliquons dans quelles circonstances il est possible d'obtenir un acquittement en raison d'une erreur policière !"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour Alcool au volant">
            <p>
                Être reconnu coupable de conduite avec les facultés affaiblies
                par l’alcool au Québec entraîne des sanctions sévères ainsi que
                des répercussions juridiques et financières considérables.
            </p>
            <p>
                Toutefois, lorsqu’ils interpellent un conducteur pour cette
                infraction, les policiers peuvent commettre des erreurs lors de
                l’arrestation ou du test d’alcoolémie. Ces irrégularités peuvent
                compromettre la validité des preuves et entraîner un
                acquittement.
            </p>
            <ul>
                <li>
                    <strong>
                        Mais dans quels cas une erreur policière peut-elle
                        réellement mener à un acquittement ?
                    </strong>
                </li>
            </ul>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Définition de conduite avec facultés affaiblies par l’alcool!"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Cet article examine l’infraction de conduite
                                avec les facultés affaiblies et met en lumière
                                les situations où un acquittement pourrait être
                                obtenu en raison d’une erreur policière!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
            <h2>
                Qu’est-ce que la conduite avec facultés affaiblies par l’alcool
                ?
            </h2>
            <p>
                Avant d'examiner les erreurs policières pouvant mener à un
                acquittement, il est essentiel de bien comprendre les éléments
                nécessaires pour qu'un conducteur soit reconnu coupable de
                conduite avec les facultés affaiblies par l'alcool.
            </p>
            <p>
                La conduite avec facultés affaiblies par l’alcool est une
                infraction prévue à{' '}
                <Link to="https://laws-lois.justice.gc.ca/fra/lois/c-46/section-320.14.html">
                    l’article 320.14
                </Link>{' '}
                du <em>Code criminel</em>. Elle peut être constatée dans deux
                situations distinctes :
            </p>
            <ol>
                <li>
                    <strong>
                        Conduite avec des facultés affaiblies par l'alcool, les
                        drogues ou une combinaison des deux
                    </strong>{' '}
                    : Un conducteur est en infraction s’il prend le volant alors
                    que ses capacités sont altérées par l’alcool, les drogues ou
                    une combinaison des deux, même si son taux d’alcoolémie est
                    inférieur à la limite légale.
                </li>
                <li>
                    <strong>
                        Concentration d'alcool égale ou supérieure à 80
                        milligrammes par 100 millilitres de sang
                    </strong>
                    : Un conducteur est coupable s’il présente un taux d’alcool
                    égal ou supérieur à 80 mg d’alcool par 100 ml de sang dans
                    les deux heures suivant la conduite.
                </li>
            </ol>
            <p>
                Toutefois, il existe une exception : si l’alcool a été consommé
                uniquement après avoir cessé de conduire, l’infraction ne
                s’applique pas.
            </p>
            <p>
                Être reconnu coupable de conduite avec les facultés affaiblies{' '}
                <Link to="https://avocatsalcoolauvolant.ca/consequences-alcool-volant/">
                    <strong>entraîne son lot de conséquences!</strong>
                </Link>
            </p>
            <h2>
                Est-il possible d’être acquitté pour conduite avec les facultés
                affaiblies par l’alcool en cas d’erreur de la police ?
            </h2>
            <p>
                <strong>La réponse est oui ! </strong>Être accusé de conduite
                avec facultés affaiblies par l'alcool ne signifie être reconnu
                coupable. Comme dans toute affaire criminelle, l'accusé
                bénéficie de la présomption d'innocence.{' '}
            </p>
            <p>
                Cela signifie qu'il incombe à la poursuite, représentée par le
                procureur de la Couronne, de prouver la culpabilité de l'accusé.
                La poursuite porte le fardeau de la preuve et doit démontrer,
                hors de tout doute raisonnable, que l'accusé est coupable de
                conduite avec facultés affaiblies par l'alcool, en présentant
                des éléments de preuve à l'appui de cette accusation.
            </p>
            <h4>
                Si la poursuite ne parvient pas à remplir son fardeau de la
                preuve, l'accusé sera acquitté!
            </h4>
            <p>
                Pour qu'une personne soit déclarée coupable de l'infraction
                criminelle de conduite avec les facultés affaiblies, la
                poursuite doit prouver, hors de tout doute raisonnable, trois
                éléments essentiels :
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Éléments à prouver</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ol>
                            <li>L'identité du conducteur</li>
                        </ol>
                    </td>
                    <td>
                        <p>
                            La poursuite doit prouver que l'accusé était bien la
                            personne qui conduisait ou avait le contrôle ou la
                            garde du véhicule au moment des événements
                            reprochés.{' '}
                        </p>
                        <p>
                            Cela signifie que l'individu arrêté ou intercepté
                            est effectivement celui qui se trouvait au volant.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ol>
                            <li>
                                Le fait de conduire ou d'avoir la garde du
                                véhicule
                            </li>
                        </ol>
                    </td>
                    <td>
                        <p>
                            La poursuite doit prouver que l'accusé conduisait un
                            véhicule ou en avait la garde ou le contrôle au
                            moment de l'infraction. Selon l'article{' '}
                            <Link to="https://canlii.ca/t/ckjd#art320.35">
                                320.35
                            </Link>{' '}
                            du <em>Code criminel</em>, si l'accusé est assis
                            derrière le volant, il est présumé avoir le contrôle
                            du véhicule.
                        </p>
                        <p>
                            La loi interdit non seulement la conduite avec les
                            facultés affaiblies, mais aussi le fait d'être en
                            contrôle du véhicule dans cet état.{' '}
                        </p>
                        <p>
                            Des éléments tels que la possession des clés,
                            l'utilisation des accessoires du véhicule et l'état
                            d'intoxication de l'accusé aident à établir si ce
                            dernier avait réellement le contrôle du véhicule.
                        </p>
                        <p>
                            Si l'accusé était derrière le volant, il devra
                            prouver que le véhicule était hors d'état de rouler
                            ou qu'il avait un plan concret pour rentrer chez lui
                            en toute sécurité.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ol>
                            <li>L'affaiblissement des capacités de conduite</li>
                        </ol>
                    </td>
                    <td>
                        <p>
                            La poursuite doit démontrer que l’accusé avait ses
                            facultés affaiblies par l’alcool en combinaison avec
                            des drogues, à un degré tel qu'il ne pouvait plus
                            conduire de manière sécuritaire.{' '}
                        </p>
                        <p>
                            Alternativement, il peut être prouvé que, dans les
                            deux heures suivant la fin de la conduite, le taux
                            d'alcool dans son sang dépassait la limite légale
                            permise (à 80 mg d’alcool par 100 ml de sang).{' '}
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <strong>
                    Si la poursuite échoue à prouver ces trois éléments hors de
                    tout doute raisonnable, l’accusé doit être acquitté.
                </strong>
            </p>
            <p>
                <strong>
                    <Img
                        fluid={data.img1.childImageSharp.fluid}
                        title="Fardeau de la preuve pour alcool au volant!"
                        alt=""
                    />
                </strong>
            </p>
            <p>
                Le procureur de la Couronne peut rencontrer des difficultés à
                remplir son fardeau de la preuve si la police a commis des
                erreurs durant et après l’arrestation, et que des preuves
                essentielles sont écartées par le tribunal en raison de
                violations des droits constitutionnels de l’accusé par les
                policiers.
            </p>
            <h4>
                <strong>
                    <strong>
                        Les protections constitutionnelles de l'accusé
                    </strong>
                </strong>
            </h4>
            <p>
                Les personnes arrêtées et accusées de conduite avec facultés
                affaiblies par l'alcool bénéficient de protections garanties par
                la <em>Charte canadienne des droits et libertés</em>. Si ces
                droits sont violés par la police, la défense, par l’entremise de
                l’accusé ou de son avocat, peut présenter une requête en
                exclusion des éléments de preuve recueillis en violation des
                droits constitutionnels de l’accusé.
            </p>
            <p>
                L'exclusion de ces preuves pourrait empêcher la Couronne de
                remplir son fardeau de la preuve et mener ainsi à l’acquittement
                du conducteur.
            </p>
            <p>
                Il existe plusieurs raisons pour lesquelles une personne accusée
                de conduite avec facultés affaiblies par l'alcool peut être
                acquittée.{' '}
                <strong>
                    Voici trois erreurs fréquemment commises par la police qui
                    peuvent entraîner l'acquittement de l'accusé :
                </strong>
            </p>
            <h3>Le droit à l’assistance d’un avocat a été violé !</h3>
            <p>
                Lorsqu’une personne est arrêtée et accusée de conduite avec
                facultés affaiblies par l’alcool, elle peut se voir acquittée si
                son droit à l’assistance d’un avocat est violé.
            </p>
            <p>
                Ce droit, protégé par{' '}
                <Link to="https://www.canlii.org/fr/ca/legis/lois/annexe-b-de-la-loi-de-1982-sur-le-canada-r-u-1982-c-11/derniere/annexe-b-de-la-loi-de-1982-sur-le-canada-r-u-1982-c-11.html#art10">
                    l'article 10b)
                </Link>{' '}
                de la <em>Charte canadienne des droits et libertés</em>, est
                crucial pour garantir une défense aux conducteurs arrêtés, en
                prévenant toute coercition ou pression de la part des policiers.{' '}
            </p>
            <h4>Obligation des policiers envers l’accusé</h4>
            <p>
                <strong>
                    Les policiers ont l’obligation d’informer immédiatement la
                    personne arrêtée de son droit à l’assistance d’un avocat.
                    Dès l'arrestation, ils doivent faciliter l'accès à un
                    avocat, que ce soit :
                </strong>
            </p>
            <ul>
                <li>
                    <strong>En contactant un avocat de garde</strong>
                </li>
                <li>
                    <strong>En fournissant une liste d'avocats locaux</strong>
                </li>
                <li>
                    <strong>
                        En informant l’accusé de son éligibilité à l’aide
                        juridique
                    </strong>
                </li>
            </ul>
            <p>
                Cette obligation prend effet dès l’arrestation, et dès que
                l’accusé demande un avocat, il doit pouvoir le contacter sans
                délai excessif. L’objectif est de permettre à l’accusé d’obtenir
                des conseils juridiques avant de prendre toute décision
                concernant sa coopération avec les policiers, ce qui réduit le
                risque d'auto-incrimination.
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Droit à un avocat après une arrestation pour alcool au volant !"
                    alt=""
                />
            </p>
            <p>
                Les policiers doivent également permettre à la personne
                d'utiliser un téléphone pour contacter un avocat dès que
                possible. Tant que l'accès à un avocat n'est pas facilité, les
                policiers ne doivent pas recueillir de preuves.{' '}
            </p>
            <h4>Les conséquences de la violation du droit à l’avocat</h4>
            <p>
                Une violation de ce droit compromet gravement la capacité de
                l’accusé à se défendre correctement. Si l’accès à un avocat est
                retardé, cela peut entraîner une auto-incrimination
                involontaire, compromettant ainsi l'équité du procès. Le juge
                peut exclure les preuves obtenues pendant cette période de
                violation des droits constitutionnels de l’accusé.
            </p>
            <h4>Exemple d’acquittement</h4>
            <p>
                Dans l’affaire{' '}
                <Link to="https://www.canlii.org/fr/qc/qccq/doc/2024/2024qccq4283/2024qccq4283.html">
                    R. c. Labonté
                </Link>
                , jugée par la Cour du Québec en août 2024, l'accusée a dû
                patienter 20 minutes avant de pouvoir consulter un avocat,
                malgré une demande explicite de sa part. L'absence de
                justification pour ce retard a conduit à l'exclusion des
                éléments de preuve obtenus durant cette violation, entraînant
                ainsi l'acquittement de l'accusée.
            </p>
            <h3>
                <strong>
                    <strong>
                        Le droit de connaître les motifs de l’arrestation a été
                        violé
                    </strong>
                </strong>
            </h3>
            <p>
                Selon{' '}
                <Link to="https://www.canlii.org/fr/ca/legis/lois/annexe-b-de-la-loi-de-1982-sur-le-canada-r-u-1982-c-11/derniere/annexe-b-de-la-loi-de-1982-sur-le-canada-r-u-1982-c-11.html#art10">
                    l'article 10a)
                </Link>{' '}
                de la <em>Charte canadienne des droits et libertés</em>, toute
                personne arrêtée doit être informée immédiatement des motifs de
                son arrestation.{' '}
            </p>
            <p>
                Dans le cadre d'une arrestation pour conduite avec facultés
                affaiblies par l'alcool, cette information doit être donnée de
                manière claire et compréhensible, sans utiliser de termes
                techniques ou juridiques complexes.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Le conducteur doit être informé de manière
                                claire par les policiers qu’il est arrêté pour
                                conduite avec les facultés affaiblies!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Ce droit fondamental permet à l'accusé de bien comprendre les
                raisons de son arrestation, ainsi que les conséquences
                juridiques qui en découlent. Cela lui permet également de
                prendre des décisions éclairées, comme celle de consulter un
                avocat, afin de mieux se défendre contre les accusations.
            </p>
            <h4>Les conséquences d’une violation de ce droit</h4>
            <p>
                Si la police ne communique pas clairement et immédiatement les
                motifs de l'arrestation, cela constitue une violation de
                l'article 10a) de la Charte canadienne.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Droit de connaître les motifs de l’arrestation !"
                    alt=""
                />
            </p>
            <p>
                En conséquence, les preuves obtenues durant cette période de
                confusion ou d'ignorance peuvent être déclarées inadmissibles
                devant le tribunal. Ainsi, cette erreur des policiers dans
                l'information donnée à l'accusé peut conduire à son
                acquittement.
            </p>
            <h3>Erreur dans l’analyse de l'échantillon d'haleine</h3>
            <p>
                Comme indiqué précédemment, pour qu’un conducteur soit déclaré
                coupable de conduite avec facultés affaiblies, le procureur de
                la Couronne doit démontrer que l’aptitude du conducteur était
                altérée par l’alcool, les drogues, ou une combinaison des deux.{' '}
            </p>
            <p>
                À défaut, il doit prouver qu’au cours des deux heures suivant la
                conduite, le taux d’alcoolémie était de 80 mg ou plus d’alcool
                par 100 ml de sang.
            </p>
            <h4>
                <strong>
                    <strong>
                        Les policiers doivent établir le taux d’alcoolémie
                    </strong>
                </strong>
            </h4>
            <p>
                Lorsqu’un conducteur est soupçonné de conduite avec facultés
                affaiblies par l'alcool, le taux d’alcoolémie doit être établi
                selon des règles précises pour que les résultats des tests
                soient jugés valides.{' '}
            </p>
            <p>
                <Link to="https://laws-lois.justice.gc.ca/fra/lois/c-46/page-48.html#docCont">
                    L’article 320.31(1)
                </Link>{' '}
                du <em>Code criminel</em> définit les conditions strictes pour
                que les échantillons d’haleine prélevés par les policiers et
                mesurés par un éthylomètre soient considérés comme fiables.
            </p>
            <p>
                Pour que l’alcoolémie d’un accusé soit prouvée hors de tout
                doute raisonnable, les conditions suivantes doivent être
                remplies :
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Conditions</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Test à blanc et étalonnage par un technicien
                            qualifié
                        </p>
                    </td>
                    <td>
                        <p>
                            Avant de procéder au prélèvement des échantillons
                            d’haleine, le policier, en tant que technicien
                            qualifié, doit effectuer un test à blanc pour
                            s'assurer que l’éthylomètre est correctement
                            calibré.{' '}
                        </p>
                        <p>
                            Ce test doit donner un résultat inférieur ou égal à
                            10 mg d’alcool par 100 ml de sang.{' '}
                        </p>
                        <p>
                            Ensuite, un test d’étalonnage est effectué pour
                            vérifier que l’éthylomètre présente un écart maximal
                            de 10 % par rapport à la valeur cible d'alcool
                            certifiée par un analyste.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Intervalle entre les échantillons</p>
                    </td>
                    <td>
                        <p>
                            Les échantillons doivent être prélevés à au moins 15
                            minutes d’intervalle. Cela garantit que les mesures
                            sont prises à des moments distincts, permettant
                            ainsi une analyse plus précise du taux d’alcoolémie
                            du conducteur.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Concordance des résultats</p>
                    </td>
                    <td>
                        <p>
                            Les résultats des analyses doivent être arrondis à
                            la dizaine inférieure et ne doivent pas présenter
                            une différence de plus de 20 mg d’alcool par 100 ml
                            de sang entre les deux échantillons.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Si ces conditions ne sont pas respectées, un doute raisonnable
                peut être soulevé quant au taux d'alcoolémie, ce qui pourrait
                conduire à l'acquittement de l'accusé.
            </p>
            <p>
                Bref, plusieurs erreurs courantes commises par les policiers
                peuvent mener à l’acquittement de l’accusé dans une affaire de
                conduite avec facultés affaiblies par l’alcool!
            </p>
            <p>
                Si l’accusé ne bénéficie pas de son droit à l’assistance d’un
                avocat ou si les policiers ne lui expliquent pas clairement les
                raisons de son arrestation, cela peut entraîner son
                acquittement. De plus, si les tests de l’échantillon d’haleine
                ne respectent pas les procédures rigoureuses, un doute
                raisonnable peut surgir concernant la validité des résultats.
            </p>
            <h2>
                Vous avez été arrêté pour alcool au volant? Consultez un avocat
                spécialisé en droit criminel !
            </h2>
            <p>
                Si vous avez été arrêté pour conduite avec les facultés
                affaiblies par l’alcool et que des accusations criminelles ont
                été portées contre vous, il est absolument nécessaire de
                consulter un{' '}
                <Link to="https://avocatsalcoolauvolant.ca/avocat-conduite-facultes-affaiblies/">
                    <strong>avocat spécialisé en droit criminel</strong>
                </Link>
                . Les erreurs policières, telles que des violations de vos
                droits ou des vices de procédure, peuvent compromettre la
                validité des preuves contre vous.
            </p>
            <p>
                Un avocat expérimenté saura analyser votre dossier, identifier
                les erreurs commises et demander l'exclusion des preuves
                obtenues en violation de vos droits constitutionnels.
                L'exclusion de ces éléments peut conduire à votre acquittement.
                En faisant appel à un avocat spécialisé dans le domaine, vous
                maximisez vos chances d'obtenir un verdict favorable.
            </p>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Acquittement pour alcool au volant avec un avocat !"
                    alt=""
                />
            </p>
            <p>
                <strong>
                    Avec notre formulaire rapide et gratuit, vous pourrez
                    facilement trouver un avocat spécialisé en droit criminel !
                </strong>
            </p>
            <p>
                <strong>
                    Suite à votre demande, nous vous mettrons rapidement en
                    contact avec un avocat près de chez vous. Ce processus ne
                    nécessite aucun engagement de votre part !
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "acquittement-alcool-volant-erreur-police/definition-conduite-facultes-affaiblies-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "acquittement-alcool-volant-erreur-police/fardeau-preuve-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "acquittement-alcool-volant-erreur-police/droit-avocat-arrestation-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "acquittement-alcool-volant-erreur-police/droit-connaitre-motifs-arrestation.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "acquittement-alcool-volant-erreur-police/acquittement-alcool-volant-avocat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
